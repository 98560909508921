import React,{Fragment} from 'react';
import './FacebookPageEmbed.css'; // Make sure to import the CSS

const FacebookPageEmbed = () => {
  return (

    <iframe
        src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fhintschoolsofficial&tabs=timeline&width=500&height=700&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true"
        width="500"
        height="700"
        style={{ border: 'none', overflow: 'hidden' }}
        scrolling="no"
        frameBorder="0"
        allowTransparency="true"
        allow="encrypted-media"
        title="Facebook Page"
    >

    </iframe>
  );
};

export default FacebookPageEmbed;
