import React from 'react';

const InstagramProfileEmbed = () => {
  return (
      <iframe
        src="https://www.instagram.com/hintschoolsofficial/embed"
        width="500"
        height="700"
        frameBorder="0"
        scrolling="no"
        allowTransparency="true"
        title="Instagram Profile"
      ></iframe>
  );
};

export default InstagramProfileEmbed;
